import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, inject, ElementRef, input, viewChild, computed, effect, HostListener, NgModule, signal, Injectable, model, Injector, output, HostBinding, Renderer2, Directive, contentChild } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
const _c0 = ["*"];
const _c1 = [[["", "spk-alert-icon", ""]], [["", "spk-alert-title", ""]], "*", [["", "spk-alert-content", ""]]];
const _c2 = ["[spk-alert-icon]", "[spk-alert-title]", "*", "[spk-alert-content]"];
function SparkleAlertComponent_Conditional_4_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "info");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Case_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "info");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Case_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "check-circle");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Case_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "warning-octagon");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Case_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "warning");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Case_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "question");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, SparkleAlertComponent_Conditional_4_Case_1_Template, 2, 0, "spk-icon", 6)(2, SparkleAlertComponent_Conditional_4_Case_2_Template, 2, 0, "spk-icon", 6)(3, SparkleAlertComponent_Conditional_4_Case_3_Template, 2, 0, "spk-icon", 6)(4, SparkleAlertComponent_Conditional_4_Case_4_Template, 2, 0, "spk-icon", 6)(5, SparkleAlertComponent_Conditional_4_Case_5_Template, 2, 0, "spk-icon", 6)(6, SparkleAlertComponent_Conditional_4_Case_6_Template, 2, 0, "spk-icon", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_2_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional((tmp_2_0 = ctx_r0.type()) === "primary" ? 1 : tmp_2_0 === "accent" ? 2 : tmp_2_0 === "success" ? 3 : tmp_2_0 === "error" ? 4 : tmp_2_0 === "warning" ? 5 : 6);
  }
}
function SparkleAlertComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-icon", 7);
    i0.ɵɵlistener("click", function SparkleAlertComponent_Conditional_8_Template_spk_icon_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.removeAlert());
    });
    i0.ɵɵtext(1, "plus");
    i0.ɵɵelementEnd();
  }
}
const _c3 = ["alerts"];
const _c4 = ["scroller"];
function SparkleAlertContainerComponent_For_4_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", alert_r2.content, " ");
  }
}
function SparkleAlertContainerComponent_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-alert", 7, 1)(2, "div", 8);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, SparkleAlertContainerComponent_For_4_Conditional_4_Template, 2, 1, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r2 = ctx.$implicit;
    const $index_r3 = ctx.$index;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("transition-delay", ctx_r3.transitionDelay($index_r3, true));
    i0.ɵɵclassProp("spk-alert-animate-in", alert_r2.animateIn)("spk-alert-animate-out", alert_r2.animateOut)("spk-alert-is-hidden", ctx_r3.alertHistoryIsHidden() && !alert_r2.isOpen);
    i0.ɵɵproperty("type", alert_r2.type)("id", alert_r2.id)("alertService", ctx_r3.alertService());
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(alert_r2.title);
    i0.ɵɵadvance();
    i0.ɵɵconditional(alert_r2.content ? 4 : -1);
  }
}
const _c5 = ["spk-button", ""];
const _c6 = ["inputWrap"];
const _c7 = [[["label"]], [["input"]]];
const _c8 = ["label", "input"];
function SparkleFileUploadComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "spk-icon");
    i0.ɵɵtext(2, "circle");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const file_r2 = ctx.$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", file_r2.name, " ");
  }
}
const _c9 = [[["label"]], [["", "spkPrefix", ""]], [["", "spkTextPrefix", ""]], [["input"]], [["textarea"]], [["", "spkTextSuffix", ""]], [["", "spkSuffix", ""]], [["", "spkError", ""]], [["", "spkHint", ""]]];
const _c10 = ["label", "[spkPrefix]", "[spkTextPrefix]", "input", "textarea", "[spkTextSuffix]", "[spkSuffix]", "[spkError]", "[spkHint]"];
const _c11 = ["actionRef"];
const _c12 = ["menuRef"];
const _c13 = ["*", [["", "menu", ""]]];
const _c14 = ["*", "[menu]"];
const _c15 = [[["", "spkLabel", ""]], "*"];
const _c16 = ["[spkLabel]", "*"];
const _c17 = ["optionsRef"];
const _c18 = ["formFieldWrapper"];
const _c19 = [[["label"]], [["input"]], [["", "deselect-indicator", ""]], [["", "search-indicator", ""]], [["", "open-indicator", ""]], [["", "placeholder-text", ""]], [["", "options", ""]]];
const _c20 = ["label", "input", "[deselect-indicator]", "[search-indicator]", "[open-indicator]", "[placeholder-text]", "[options]"];
function SparkleSelectComponent_Conditional_6_Conditional_1_For_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-chip", 11);
    i0.ɵɵlistener("click", function SparkleSelectComponent_Conditional_6_Conditional_1_For_1_Template_spk_chip_click_0_listener($event) {
      const option_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r3 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r3.toggleOption(option_r3, $event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "spk-icon");
    i0.ɵɵtext(3, "x-circle");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_15_0;
    const option_r3 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r3.chipClass());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (tmp_15_0 = (tmp_15_0 = ctx_r3.displayFn()) == null ? null : tmp_15_0(option_r3)) !== null && tmp_15_0 !== undefined ? tmp_15_0 : option_r3, " ");
  }
}
function SparkleSelectComponent_Conditional_6_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵrepeaterCreate(0, SparkleSelectComponent_Conditional_6_Conditional_1_For_1_Template, 4, 2, "spk-chip", 10, i0.ɵɵrepeaterTrackByIndex);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵrepeater(ctx_r3.inputValue().split(","));
  }
}
function SparkleSelectComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, SparkleSelectComponent_Conditional_6_Conditional_1_Template, 2, 0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r3.inputValue().length > 0 ? 1 : -1);
  }
}
function SparkleSelectComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3._displayValue());
  }
}
function SparkleSelectComponent_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵlistener("click", function SparkleSelectComponent_Conditional_9_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.deselect($event));
    });
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementStart(2, "spk-icon", 13);
    i0.ɵɵtext(3, "x-circle");
    i0.ɵɵelementEnd()();
  }
}
function SparkleSelectComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵprojection(1, 3);
    i0.ɵɵelementStart(2, "spk-icon", 13);
    i0.ɵɵtext(3, "magnifying-glass");
    i0.ɵɵelementEnd()();
  }
}
function SparkleSelectComponent_Conditional_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵprojection(1, 4);
    i0.ɵɵelementStart(2, "spk-icon", 13);
    i0.ɵɵtext(3, "caret-down");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("open", ctx_r3.isOpen());
  }
}
function SparkleSelectComponent_Conditional_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15, 2)(2, "div", 16);
    i0.ɵɵlistener("click", function SparkleSelectComponent_Conditional_12_Template_div_click_2_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.close());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 17)(4, "div", 18);
    i0.ɵɵprojection(5, 5);
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(6, 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r3.optionsStyle());
    i0.ɵɵclassProp("active", ctx_r3.delayedIsOpen());
  }
}
const _c21 = ["dragImageElement"];
const _c22 = [[["", "sidenav", ""]], [["", "sidenav-closed-topbar", ""]], "*"];
const _c23 = ["[sidenav]", "[sidenav-closed-topbar]", "*"];
function SparkleSidenavComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵlistener("drop", function SparkleSidenavComponent_Conditional_2_Template_div_drop_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.drop($event));
    })("dragover", function SparkleSidenavComponent_Conditional_2_Template_div_dragover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragOver($event));
    })("dragenter", function SparkleSidenavComponent_Conditional_2_Template_div_dragenter_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragEnter());
    })("dragleave", function SparkleSidenavComponent_Conditional_2_Template_div_dragleave_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragLeave());
    });
    i0.ɵɵelementEnd();
  }
}
function SparkleSidenavComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("dragstart", function SparkleSidenavComponent_Conditional_6_Template_div_dragstart_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragStart($event));
    })("dragend", function SparkleSidenavComponent_Conditional_6_Template_div_dragend_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragEnd($event));
    })("drag", function SparkleSidenavComponent_Conditional_6_Template_div_drag_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.drag($event));
    })("touchstart", function SparkleSidenavComponent_Conditional_6_Template_div_touchstart_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchStart($event));
    })("touchmove", function SparkleSidenavComponent_Conditional_6_Template_div_touchmove_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchMove($event));
    })("touchend", function SparkleSidenavComponent_Conditional_6_Template_div_touchend_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchEnd($event));
    })("touchcancel", function SparkleSidenavComponent_Conditional_6_Template_div_touchcancel_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchCancel($event));
    });
    i0.ɵɵelementEnd();
  }
}
const _c24 = ["templateOne"];
const _c25 = ["templateTwo"];
const _c26 = ["placeholderRef"];
const _c27 = a0 => ({
  $implicit: a0
});
function SparkleSortableComponent_For_3_Conditional_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSortableComponent_For_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_0_ng_container_0_Template, 1, 0, "ng-container", 2);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.templateOne())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c27, item_r1));
  }
}
function SparkleSortableComponent_For_3_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSortableComponent_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 2);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.templateTwo())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c27, item_r1));
  }
}
function SparkleSortableComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_0_Template, 1, 4, "ng-container")(1, SparkleSortableComponent_For_3_Conditional_1_Template, 1, 4, "ng-container");
  }
  if (rf & 2) {
    const $index_r3 = ctx.$index;
    i0.ɵɵconditional($index_r3 === 0 ? 0 : 1);
  }
}
const _c28 = [[["", "table-header", ""]], "*", [["", "table-no-rows", ""]]];
const _c29 = ["[table-header]", "*", "[table-no-rows]"];
function SparkleTableComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spk-progress-bar", 0);
  }
}
function SparkleTableComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
}
const _c30 = [[["", "title", ""]], "*"];
const _c31 = ["[title]", "*"];
function SparkleToggleCardComponent_ProjectionFallback_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "Title");
  }
}
function SparkleToggleCardComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon");
    i0.ɵɵtext(1, "caret-down");
    i0.ɵɵelementEnd();
  }
}
class SparkleIconComponent {
  static {
    this.ɵfac = function SparkleIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleIconComponent,
      selectors: [["spk-icon"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleIconComponent, [{
    type: Component,
    args: [{
      selector: 'spk-icon',
      standalone: true,
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleAlertComponent {
  constructor() {
    this._el = inject(ElementRef);
    this.alertService = input(null);
    this.type = input('error');
    this.id = input(null);
  }
  removeAlert() {
    if (this.id() && this.alertService()) {
      this.alertService()?.removeAlert(this.id());
    }
  }
  static {
    this.ɵfac = function SparkleAlertComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleAlertComponent,
      selectors: [["spk-alert"]],
      hostVars: 2,
      hostBindings: function SparkleAlertComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.type());
        }
      },
      inputs: {
        alertService: [1, "alertService"],
        type: [1, "type"],
        id: [1, "id"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 11,
      vars: 4,
      consts: [["ref", ""], [1, "spk-alert-item"], [1, "spk-alert-icon"], [1, "spk-alert-title"], [1, "spk-alert-close-icon"], [1, "spk-alert-content"], [1, "spk-alert-state-icon"], [1, "spk-alert-close-icon", 3, "click"]],
      template: function SparkleAlertComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2, 0);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, SparkleAlertComponent_Conditional_4_Template, 7, 1, "div", 2);
          i0.ɵɵelementStart(5, "div", 3);
          i0.ɵɵprojection(6, 1);
          i0.ɵɵprojection(7, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(8, SparkleAlertComponent_Conditional_8_Template, 2, 0, "spk-icon", 4);
          i0.ɵɵelementStart(9, "div", 5);
          i0.ɵɵprojection(10, 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const ref_r3 = i0.ɵɵreference(2);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", !ref_r3.children.length ? "none" : "block");
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ref_r3.children.length ? 4 : -1);
          i0.ɵɵadvance(4);
          i0.ɵɵconditional(ctx.id() ? 8 : -1);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertComponent, [{
    type: Component,
    args: [{
      selector: 'spk-alert',
      standalone: true,
      imports: [SparkleIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class]': 'type()'
      },
      template: "<div class=\"spk-alert-item\">\n  <div #ref class=\"spk-alert-icon\" [style.display]=\"!ref.children.length ? 'none' : 'block'\">\n    <ng-content select=\"[spk-alert-icon]\"></ng-content>\n  </div>\n\n  @if (!ref.children.length) {\n    <div class=\"spk-alert-icon\">\n      @switch (type()) {\n        @case ('primary') {\n          <spk-icon class=\"spk-alert-state-icon\">info</spk-icon>\n        }\n        @case ('accent') {\n          <spk-icon class=\"spk-alert-state-icon\">info</spk-icon>\n        }\n        @case ('success') {\n          <spk-icon class=\"spk-alert-state-icon\">check-circle</spk-icon>\n        }\n        @case ('error') {\n          <spk-icon class=\"spk-alert-state-icon\">warning-octagon</spk-icon>\n        }\n        @case ('warning') {\n          <spk-icon class=\"spk-alert-state-icon\">warning</spk-icon>\n        }\n        @default {\n          <spk-icon class=\"spk-alert-state-icon\">question</spk-icon>\n        }\n      }\n    </div>\n  }\n\n  <div class=\"spk-alert-title\">\n    <ng-content select=\"[spk-alert-title]\"></ng-content>\n    <ng-content></ng-content>\n  </div>\n\n  @if (id()) {\n    <spk-icon class=\"spk-alert-close-icon\" (click)=\"removeAlert()\">plus</spk-icon>\n  }\n\n  <div class=\"spk-alert-content\">\n    <ng-content select=\"[spk-alert-content]\"></ng-content>\n  </div>\n</div>\n"
    }]
  }], null, null);
})();
class SparkleAlertContainerComponent {
  constructor() {
    this.inline = input(null);
    this.alerts = viewChild.required('alerts');
    this.scroller = viewChild.required('scroller');
    this.alertService = input.required();
    this.alertHistory = this.alertService()?.alertHistory;
    this.alertHistoryIsOpen = this.alertService()?.alertHistoryIsOpen;
    this.alertHistoryIsHidden = this.alertService()?.alertHistoryIsHidden;
    this.numberOfOpenAlerts = computed(() => {
      return this.alertHistory().filter(x => x.isOpen).length;
    });
    this.#e = effect(() => {
      const _ = this.alertHistory();
      const _x = this.alertHistoryIsOpen();
      this.#scrollToBottom();
    });
  }
  #e;
  #scrollToBottom() {
    if (this.scroller() && this.scroller().nativeElement) {
      this.scroller().nativeElement.scrollTo(0, this.scroller().nativeElement.scrollHeight);
    }
  }
  onMouseOver() {
    if (typeof this.inline === 'string') return;
    this.alertService().setHidden(false);
  }
  onMouseOut() {
    if (typeof this.inline === 'string') return;
    this.alertService().setHidden(true);
  }
  getElementHeight(i) {
    if (!this.alerts) return 0;
    const elementHeights = this.alerts().toArray().map(element => element._el.nativeElement.querySelector('.sparkle-alert-item').offsetHeight);
    if (!elementHeights) return 0;
    let totalHeight = 0;
    const elementTransformPos = elementHeights.map((height, i) => {
      totalHeight += height;
      return totalHeight - elementHeights[0];
    });
    return elementTransformPos[i];
  }
  transformY(i) {
    return `translateY(calc(-${this.getElementHeight(i - 1)}px + (-10px * ${i})))`;
  }
  transitionDelay(i, allOpen = false) {
    return allOpen ? this.alertHistory().length - 1 * 40 + 'ms' : (this.numberOfOpenAlerts() - i) * 40 + 'ms';
  }
  static {
    this.ɵfac = function SparkleAlertContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertContainerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleAlertContainerComponent,
      selectors: [["sparkle-alert-container"]],
      viewQuery: function SparkleAlertContainerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.alerts, _c3, 5);
          i0.ɵɵviewQuerySignal(ctx.scroller, _c4, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      hostBindings: function SparkleAlertContainerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseover", function SparkleAlertContainerComponent_mouseover_HostBindingHandler() {
            return ctx.onMouseOver();
          })("mouseout", function SparkleAlertContainerComponent_mouseout_HostBindingHandler() {
            return ctx.onMouseOut();
          });
        }
      },
      inputs: {
        inline: [1, "inline"],
        alertService: [1, "alertService"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 8,
      vars: 2,
      consts: [["scroller", ""], ["alerts", ""], [1, "scroller"], [1, "container"], [3, "type", "id", "alertService", "transition-delay", "spk-alert-animate-in", "spk-alert-animate-out", "spk-alert-is-hidden"], [1, "alert-tray", 3, "mouseover", "mouseout"], [1, "small"], [3, "type", "id", "alertService"], ["spk-alert-title", ""], ["spk-alert-content", ""]],
      template: function SparkleAlertContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 2, 0)(2, "div", 3);
          i0.ɵɵrepeaterCreate(3, SparkleAlertContainerComponent_For_4_Template, 5, 13, "spk-alert", 4, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 5);
          i0.ɵɵlistener("mouseover", function SparkleAlertContainerComponent_Template_div_mouseover_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onMouseOver());
          })("mouseout", function SparkleAlertContainerComponent_Template_div_mouseout_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onMouseOut());
          });
          i0.ɵɵelementStart(6, "spk-icon", 6);
          i0.ɵɵtext(7, "info");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵrepeater(ctx.alertHistory());
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("alert-tray-is-hidden", !ctx.alertHistoryIsHidden());
        }
      },
      dependencies: [SparkleAlertComponent, SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertContainerComponent, [{
    type: Component,
    args: [{
      selector: 'sparkle-alert-container',
      standalone: true,
      imports: [SparkleAlertComponent, SparkleIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"scroller\" #scroller>\n  <div class=\"container\">\n    @for (alert of alertHistory(); track $index) {\n      <spk-alert\n        #alerts\n        [type]=\"alert.type\"\n        [id]=\"alert.id\"\n        [alertService]=\"alertService()\"\n        [style.transition-delay]=\"transitionDelay($index, true)\"\n        [class.spk-alert-animate-in]=\"alert.animateIn\"\n        [class.spk-alert-animate-out]=\"alert.animateOut\"\n        [class.spk-alert-is-hidden]=\"alertHistoryIsHidden() && !alert.isOpen\">\n        <div spk-alert-title>{{ alert.title }}</div>\n\n        @if (alert.content) {\n          <div spk-alert-content>\n            {{ alert.content }}\n          </div>\n        }\n      </spk-alert>\n    }\n  </div>\n</div>\n<div\n  class=\"alert-tray\"\n  [class.alert-tray-is-hidden]=\"!alertHistoryIsHidden()\"\n  (mouseover)=\"this.onMouseOver()\"\n  (mouseout)=\"this.onMouseOut()\">\n  <spk-icon class=\"small\">info</spk-icon>\n</div>\n"
    }]
  }], null, {
    onMouseOver: [{
      type: HostListener,
      args: ['mouseover']
    }],
    onMouseOut: [{
      type: HostListener,
      args: ['mouseout']
    }]
  });
})();
class SparkleAlertModule {
  static {
    this.ɵfac = function SparkleAlertModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SparkleAlertModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertModule, [{
    type: NgModule,
    args: [{
      imports: [SparkleAlertComponent, SparkleAlertContainerComponent],
      exports: [SparkleAlertComponent, SparkleAlertContainerComponent],
      providers: []
    }]
  }], null, null);
})();
class SparkleAlertService {
  constructor() {
    this.alertHistory = signal([]);
    this.alertHistoryIsOpen = signal(false);
    this.alertHistoryIsHidden = signal(true);
  }
  error(message) {
    this.addAlert({
      type: 'error',
      title: message ?? 'An error occured'
    });
  }
  success(message) {
    this.addAlert({
      type: 'success',
      title: message
    });
  }
  question(message) {
    this.addAlert({
      type: 'question',
      title: message
    });
  }
  warning(message) {
    this.addAlert({
      type: 'warning',
      title: message
    });
  }
  info(message) {
    this.addAlert({
      type: 'primary',
      title: message
    });
  }
  addAlert(alert) {
    const id = crypto.randomUUID();
    this.alertHistory.update(history => [{
      ...alert,
      isOpen: true,
      animateIn: true,
      animateOut: false,
      id
    }, ...history]);
    setTimeout(() => {
      this.alertHistory.update(history => history.map(item => ({
        ...item,
        animateIn: item.id === id ? false : item.animateIn
      })));
    }, 40);
    setTimeout(() => {
      this.hideAlert(id);
    }, 2500);
  }
  removeAlert(id) {
    this.alertHistory.update(history => history.map(item => ({
      ...item,
      animateOut: item.id === id ? false : item.animateOut
    })));
    setTimeout(() => {
      this.alertHistory.update(history => history.filter(item => item.id !== id));
    }, 300);
  }
  hideAlert(id) {
    this.alertHistory.update(history => history.map(item => ({
      ...item,
      isOpen: item.id === id ? false : item.isOpen
    })));
  }
  setHidden(isHidden) {
    this.alertHistoryIsHidden.set(isHidden);
  }
  static {
    this.ɵfac = function SparkleAlertService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SparkleAlertService,
      factory: SparkleAlertService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SparkleButtonGroupComponent {
  static {
    this.ɵfac = function SparkleButtonGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleButtonGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleButtonGroupComponent,
      selectors: [["spk-button-group"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleButtonGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleButtonGroupComponent, [{
    type: Component,
    args: [{
      selector: 'spk-button-group',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleButtonComponent {
  static {
    this.ɵfac = function SparkleButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleButtonComponent,
      selectors: [["", "spk-button", ""]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      attrs: _c5,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleButtonComponent, [{
    type: Component,
    args: [{
      selector: '[spk-button]',
      standalone: true,
      imports: [],
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleCardComponent {
  static {
    this.ɵfac = function SparkleCardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleCardComponent,
      selectors: [["spk-card"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleCardComponent, [{
    type: Component,
    args: [{
      selector: 'spk-card',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleCheckboxComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
  }
  static {
    this.ɵfac = function SparkleCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleCheckboxComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleCheckboxComponent,
      selectors: [["spk-checkbox"]],
      hostBindings: function SparkleCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleCheckboxComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 4,
      vars: 0,
      consts: [[1, "box"], [1, "inherit"]],
      template: function SparkleCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "spk-icon", 1);
          i0.ɵɵtext(2, "check");
          i0.ɵɵelementEnd()();
          i0.ɵɵprojection(3);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'spk-checkbox',
      standalone: true,
      imports: [SparkleIconComponent],
      template: `
    <div class="box">
      <spk-icon class="inherit">check</spk-icon>
    </div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleChipComponent {
  static {
    this.ɵfac = function SparkleChipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleChipComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleChipComponent,
      selectors: [["spk-chip"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleChipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleChipComponent, [{
    type: Component,
    args: [{
      selector: 'spk-chip',
      standalone: true,
      imports: [],
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleDividerComponent {
  static {
    this.ɵfac = function SparkleDividerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDividerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDividerComponent,
      selectors: [["spk-divider"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleDividerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDividerComponent, [{
    type: Component,
    args: [{
      selector: 'spk-divider',
      standalone: true,
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleFileUploadComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.inputWrapRef = viewChild.required('inputWrap');
    this.#inputRef = signal(null);
    this.#triggerInput = signal(false);
    this.files = model([]);
    this.inputRefEffect = effect(() => {
      this.#triggerInput();
      const input = this.#selfRef.nativeElement.querySelector('input');
      if (input) {
        input.autocomplete = 'off';
        this.#inputRef.set(input);
        this.#newInput();
      }
    }, {
      allowSignalWrites: true
    });
    this.inputController = null;
    this.#inputObserver = new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList') {
          this.#triggerInput.set(!this.#triggerInput());
        }
      }
    });
  }
  #selfRef;
  #inputRef;
  #triggerInput;
  ngOnInit() {
    this.#inputObserver.observe(this.inputWrapRef().nativeElement, {
      childList: true,
      subtree: true
    });
  }
  onFileDropped(files) {
    this.handleFileUpload(Array.from(files));
  }
  handleFileUpload(newFiles) {
    this.files.update(currentFiles => [...currentFiles, ...newFiles]);
  }
  #newInput() {
    if (this.inputController) {
      this.inputController.abort();
    }
    this.inputController = new AbortController();
    const input = this.#inputRef();
    if (!input) return;
    input.addEventListener('change', e => {
      const files = e.target.files;
      if (files && files.length > 0) {
        this.handleFileUpload(Array.from(files));
      }
    });
  }
  #inputObserver;
  ngOnDestroy() {
    if (this.#inputObserver) {
      this.#inputObserver.disconnect();
    }
  }
  static {
    this.ɵfac = function SparkleFileUploadComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFileUploadComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleFileUploadComponent,
      selectors: [["spk-file-upload"]],
      viewQuery: function SparkleFileUploadComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.inputWrapRef, _c6, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        files: [1, "files"]
      },
      outputs: {
        files: "filesChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c8,
      decls: 10,
      vars: 0,
      consts: [["inputWrap", ""], [1, "primary"], ["spk-list-item", ""], ["spkDragDrop", "", 3, "fileDropped"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], ["spkSuffix", ""]],
      template: function SparkleFileUploadComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c7);
          i0.ɵɵelementStart(0, "spk-list", 1);
          i0.ɵɵrepeaterCreate(1, SparkleFileUploadComponent_For_2_Template, 4, 1, "div", 2, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "spk-form-field", 3);
          i0.ɵɵlistener("fileDropped", function SparkleFileUploadComponent_Template_spk_form_field_fileDropped_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onFileDropped($event));
          });
          i0.ɵɵprojection(4, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵelementStart(5, "div", 4, 0);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "spk-icon", 5);
          i0.ɵɵtext(9, "upload-simple");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.files());
        }
      },
      dependencies: [SparkleFormFieldComponent, SparkleIconComponent, SparkleListComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFileUploadComponent, [{
    type: Component,
    args: [{
      selector: 'spk-file-upload',
      standalone: true,
      imports: [SparkleFileDragDropDirective, SparkleFormFieldComponent, SparkleIconComponent, SparkleListComponent],
      template: `
    <spk-list class="primary">
      @for (file of files(); track $index) {
        <div spk-list-item>
          <spk-icon>circle</spk-icon>
          {{ file.name }}
        </div>
      }
    </spk-list>
    <spk-form-field spkDragDrop (fileDropped)="onFileDropped($any($event))">
      <ng-content select="label" ngProjectAs="label"></ng-content>

      <div class="input" ngProjectAs="input" #inputWrap>
        <ng-content select="input"></ng-content>
      </div>

      <spk-icon spkSuffix>upload-simple</spk-icon>
    </spk-form-field>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleFormFieldComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
    if (this.#selfRef.nativeElement.querySelector('textarea')) {
      this.#selfRef.nativeElement.querySelector('textarea').focus();
    }
  }
  ngOnInit() {
    const supportFieldSizing = CSS.supports('field-sizing', 'content');
    const text = this.#selfRef.nativeElement.querySelector('textarea');
    if (!supportFieldSizing && text !== null) {
      const text = this.#selfRef.nativeElement.querySelector('textarea');
      function resize() {
        text.style.height = 'auto';
        text.style.height = text.scrollHeight + 'px';
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      text.addEventListener('change', resize);
      text.addEventListener('cut', delayedResize);
      text.addEventListener('paste', delayedResize);
      text.addEventListener('drop', delayedResize);
      text.addEventListener('keydown', delayedResize);
      text.focus();
      text.select();
      resize();
    }
  }
  static {
    this.ɵfac = function SparkleFormFieldComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFormFieldComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleFormFieldComponent,
      selectors: [["spk-form-field"]],
      hostBindings: function SparkleFormFieldComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleFormFieldComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c10,
      decls: 16,
      vars: 0,
      consts: [[1, "input-wrap"], [1, "prefix"], [1, "prefix-spacer"], [1, "suffix-spacer"], [1, "helpers"], [1, "error"], [1, "hint"]],
      template: function SparkleFormFieldComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c9);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 0)(2, "div", 1);
          i0.ɵɵprojection(3, 1);
          i0.ɵɵprojection(4, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(5, "div", 2);
          i0.ɵɵprojection(6, 3);
          i0.ɵɵprojection(7, 4);
          i0.ɵɵprojection(8, 5);
          i0.ɵɵelement(9, "div", 3);
          i0.ɵɵprojection(10, 6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(11, "div", 4)(12, "div", 5);
          i0.ɵɵprojection(13, 7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(14, "div", 6);
          i0.ɵɵprojection(15, 8);
          i0.ɵɵelementEnd()();
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFormFieldComponent, [{
    type: Component,
    args: [{
      selector: 'spk-form-field',
      standalone: true,
      imports: [],
      template: `
    <ng-content select="label"></ng-content>

    <div class="input-wrap">
      <div class="prefix">
        <ng-content select="[spkPrefix]"></ng-content>
        <ng-content select="[spkTextPrefix]"></ng-content>
      </div>

      <div class="prefix-spacer"></div>

      <ng-content select="input"></ng-content>
      <ng-content select="textarea"></ng-content>

      <ng-content select="[spkTextSuffix]"></ng-content>
      <div class="suffix-spacer"></div>
      <ng-content select="[spkSuffix]"></ng-content>
    </div>

    <div class="helpers">
      <div class="error">
        <ng-content select="[spkError]"></ng-content>
      </div>

      <div class="hint">
        <ng-content select="[spkHint]"></ng-content>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleListComponent {
  static {
    this.ɵfac = function SparkleListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleListComponent,
      selectors: [["spk-list"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleListComponent, [{
    type: Component,
    args: [{
      selector: 'spk-list',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleMenuComponent {
  constructor() {
    this.injector = inject(Injector);
    this.#BASE_SPACE = 8;
    this.above = input(false);
    this.right = input(false);
    this.closeAction = output();
    this.isActive = model(false);
    this.optionInFocus = model(null);
    this.actionRef = viewChild.required('actionRef');
    this.menuRef = viewChild.required('menuRef');
    this.menuEl = computed(() => this.menuRef()?.nativeElement);
    this.menuStyle = signal({
      left: '0px',
      top: '0px'
    });
    this.optionsElTrigger = signal(false);
    this.options = computed(() => {
      const _options = this.menuEl().querySelectorAll('[option]:not([disabled])');
      this.optionsElTrigger();
      this.isActive();
      return Array.from(_options);
    });
    this.#previousOptionElementInFocus = signal(null);
    this.optionElementInFocus = model(null);
    this.#previousSelectedOption = signal(null);
    this.selectedOption = model(null);
    this.selectedOptionIndex = model(null);
    this.#body = document.getElementsByTagName('body')[0];
    this.optionInFocusEffect = effect(() => {
      if (this.optionInFocus() === null && this.#previousOptionElementInFocus()) {
        this.#previousOptionElementInFocus()?.classList.remove('focused');
        return;
      }
      if (this.options().length === 0 || this.optionInFocus() === null) return;
      const el = this.options().at(this.optionInFocus());
      if (this.#previousOptionElementInFocus()) {
        this.#previousOptionElementInFocus()?.classList.remove('focused');
      }
      if (el) {
        el.classList.add('focused');
        el.scrollIntoView({
          block: 'nearest'
        });
        this.#previousOptionElementInFocus.set(el);
        this.optionElementInFocus.set(el);
      } else {
        this.optionElementInFocus.set(null);
      }
    }, {
      allowSignalWrites: true,
      injector: this.injector
    });
    this.controller = null;
    this.whenActive = effect(() => {
      if (this.isActive()) {
        this.setNewController();
        this.#setMenuElement();
        this.calculateMenuPosition();
        setTimeout(() => {
          const selectedIndex = this.options().findIndex(el => el.getAttribute('value') === this.selectedOption()?.getAttribute('value'));
          if (this.optionInFocus() === null) {
            this.optionInFocus.set(selectedIndex > -1 ? selectedIndex : 0);
          }
        }, 0);
        window.addEventListener('resize', () => this.calculateMenuPosition(), {
          signal: this.controller?.signal
        });
        window.addEventListener('scroll', () => this.calculateMenuPosition(), {
          signal: this.controller?.signal
        });
        window.addEventListener('keydown', e => {
          if (e.key === 'ArrowDown') {
            if (this.optionInFocus() === null || this.optionInFocus() === this.options().length - 1) {
              this.optionInFocus.set(0);
            } else {
              this.optionInFocus.set(this.optionInFocus() + 1);
            }
          } else if (e.key === 'ArrowUp') {
            if (this.optionInFocus() === null || this.optionInFocus() === 0) {
              this.optionInFocus.set(this.options().length - 1);
            } else {
              this.optionInFocus.set(this.optionInFocus() - 1);
            }
          } else if (e.key === 'Enter') {
            if (this.optionElementInFocus()) {
              this.selectOption(this.optionElementInFocus());
            }
            this.close('selected');
          } else if (e.key === 'Escape') {
            this.close('closed');
          } else {
            setTimeout(() => {
              this.optionInFocus.set(0);
              this.optionsElTrigger.set(!this.optionsElTrigger());
            }, 0);
          }
        }, {
          signal: this.controller?.signal
        });
      } else {
        this.#hideMenuElement();
      }
    }, {
      allowSignalWrites: true,
      injector: this.injector
    });
  }
  #BASE_SPACE;
  #previousOptionElementInFocus;
  #previousSelectedOption;
  #body;
  // TODO cleanup so that we dont have multiple controller effects
  // Its due to the whenActive effect being called in a loop try tracking by only isActive
  setNewController() {
    this.controller?.abort();
    this.controller = new AbortController();
  }
  deselectOption() {
    this.#previousSelectedOption.set(null);
    this.selectedOption.set(null);
    this.selectedOptionIndex.set(null);
  }
  setSelectedOption(byInputValue) {
    const option = this.options().find(x => x.hasAttribute('value') && x.getAttribute('value') === byInputValue);
    if (option) {
      this.selectOption(option);
    }
  }
  selectOption(el) {
    if (this.selectedOption()) {
      this.#previousSelectedOption.set(this.selectedOption());
    }
    this.selectedOption.set(el);
    this.selectedOptionIndex.set(this.options().indexOf(el));
    el.dispatchEvent(new Event('click', {
      bubbles: true,
      cancelable: true
    }));
  }
  selectCurrentOption() {
    const selectedOption = this.selectedOptionIndex();
    const option = this.options().at(selectedOption);
    if (option) {
      this.selectOption(option);
    }
  }
  #setMenuElement() {
    this.#body.appendChild(this.menuEl());
  }
  #hideMenuElement() {
    this.#body.removeChild(this.menuEl());
  }
  ngOnInit() {
    this.#setMenuElement();
  }
  toggle() {
    this.isActive.set(!this.isActive());
  }
  close(action = 'closed') {
    this.controller?.abort();
    this.isActive.set(false);
    this.closeAction.emit(action);
  }
  calculateMenuPosition() {
    if (this.isActive()) {
      const triggerRect = this.actionRef()?.nativeElement.getBoundingClientRect();
      const menuRect = this.menuEl().getBoundingClientRect();
      const actionLeftInViewport = triggerRect.left;
      const actionBottomInViewport = triggerRect.bottom;
      let newLeft = actionLeftInViewport;
      let newTop = actionBottomInViewport + this.#BASE_SPACE;
      const outOfBoundsRight = newLeft + menuRect.width > window.innerWidth;
      const outOfBoundsBottom = newTop + menuRect.height > window.innerHeight;
      if (this.above()) {
        const _newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        if (_newTop >= 0) {
          newTop = _newTop;
        }
      } else {
        if (outOfBoundsBottom) {
          newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        }
      }
      if (this.right()) {
        const _newLeft = triggerRect.right - menuRect.width;
        if (_newLeft >= 0) {
          newLeft = _newLeft;
        }
      } else {
        if (outOfBoundsRight) {
          newTop = outOfBoundsBottom ? triggerRect.top + triggerRect.height - menuRect.height : triggerRect.top;
          newLeft = triggerRect.left - menuRect.width - this.#BASE_SPACE;
        }
      }
      this.menuStyle.set({
        left: newLeft + 'px',
        top: newTop + 'px'
      });
    }
  }
  ngOnDestroy() {
    if (this.#body.contains(this.menuEl())) {
      this.#hideMenuElement();
    }
  }
  static {
    this.ɵfac = function SparkleMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleMenuComponent,
      selectors: [["spk-menu"]],
      viewQuery: function SparkleMenuComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.actionRef, _c11, 5);
          i0.ɵɵviewQuerySignal(ctx.menuRef, _c12, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      hostVars: 2,
      hostBindings: function SparkleMenuComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("active", ctx.isActive());
        }
      },
      inputs: {
        above: [1, "above"],
        right: [1, "right"],
        isActive: [1, "isActive"],
        optionInFocus: [1, "optionInFocus"],
        optionElementInFocus: [1, "optionElementInFocus"],
        selectedOption: [1, "selectedOption"],
        selectedOptionIndex: [1, "selectedOptionIndex"]
      },
      outputs: {
        closeAction: "closeAction",
        isActive: "isActiveChange",
        optionInFocus: "optionInFocusChange",
        optionElementInFocus: "optionElementInFocusChange",
        selectedOption: "selectedOptionChange",
        selectedOptionIndex: "selectedOptionIndexChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c14,
      decls: 8,
      vars: 8,
      consts: [["actionRef", ""], ["menuRef", ""], [1, "action", 3, "click"], [1, "sparkle-popup-menu"], [1, "sparkle-menu-backdrop", 3, "click"], [1, "sparkle-options", 3, "click"]],
      template: function SparkleMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c13);
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵlistener("click", function SparkleMenuComponent_Template_div_click_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggle());
          });
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 3, 1)(5, "div", 4);
          i0.ɵɵlistener("click", function SparkleMenuComponent_Template_div_click_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close("closed"));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "div", 5);
          i0.ɵɵlistener("click", function SparkleMenuComponent_Template_div_click_6_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close("selected"));
          });
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵstyleMap(ctx.menuStyle());
          i0.ɵɵclassProp("right", ctx.right())("above", ctx.above())("active", ctx.isActive());
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleMenuComponent, [{
    type: Component,
    args: [{
      selector: 'spk-menu',
      standalone: true,
      imports: [],
      template: `
    <div class="action" #actionRef (click)="toggle()">
      <ng-content />
    </div>

    <div
      #menuRef
      class="sparkle-popup-menu"
      [class.right]="right()"
      [class.above]="above()"
      [class.active]="isActive()"
      [style]="menuStyle()">
      <div class="sparkle-menu-backdrop" (click)="close('closed')"></div>
      <div class="sparkle-options" (click)="close('selected')">
        <ng-content select="[menu]" />
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.active]': 'isActive()'
      }
    }]
  }], null, null);
})();
class SparkleProgressBarComponent {
  constructor() {
    this.value = input(undefined);
  }
  static {
    this.ɵfac = function SparkleProgressBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleProgressBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleProgressBarComponent,
      selectors: [["spk-progress-bar"]],
      inputs: {
        value: [1, "value"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 2,
      consts: [[1, "progress-bar"]],
      template: function SparkleProgressBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.value(), "%");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleProgressBarComponent, [{
    type: Component,
    args: [{
      selector: 'spk-progress-bar',
      standalone: true,
      imports: [],
      template: `
    <div class="progress-bar" [style.width.%]="value()"></div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleRadioComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
  }
  static {
    this.ɵfac = function SparkleRadioComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleRadioComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleRadioComponent,
      selectors: [["spk-radio"]],
      hostBindings: function SparkleRadioComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleRadioComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 0,
      consts: [[1, "radio"]],
      template: function SparkleRadioComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵprojection(1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleRadioComponent, [{
    type: Component,
    args: [{
      selector: 'spk-radio',
      standalone: true,
      imports: [],
      template: `
    <div class="radio"></div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleRangeSliderComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.#observer = null;
    this.unit = input('');
    this.value = model(0);
    this.inputState = signal({
      min: 0,
      max: 100
      // value: 0,
    });
    this.inputEffect = effect(() => {
      const newVal = this.value();
      if (this.inputField && newVal !== parseInt(this.inputField.value)) {
        this.inputField.value = newVal + '';
      }
    });
  }
  #selfRef;
  #observer;
  get inputField() {
    return this.#selfRef.nativeElement.querySelector('input[type="range"]') ?? null;
  }
  trackEvent(e) {
    if (this.inputField?.readOnly) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  ngOnInit() {
    if (this.inputField) {
      this.inputState.set({
        max: parseInt(this.inputField.max ?? '') ?? 100,
        min: parseInt(this.inputField.min ?? '') ?? 0
      });
      this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
      this.inputField.oninput = e => {
        this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
      };
      const MUTATION_FIELDS = ['min', 'max', 'value'];
      this.#observer = new MutationObserver((mutationList, _) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'attributes' && MUTATION_FIELDS.includes(mutation.attributeName ?? '')) {
            this.inputState.set({
              max: parseInt(this.inputField.max ?? '') ?? 100,
              min: parseInt(this.inputField.min ?? '') ?? 0
            });
            this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
          }
        }
      });
      this.#observer.observe(this.inputField, {
        attributes: true,
        childList: false,
        subtree: false
      });
    } else {
      console.warn('No input field found');
    }
  }
  thumbWrapStyle() {
    return {
      left: `${this.value() / this.inputState().max * 100}%`
    };
  }
  thumbStyle() {
    return {
      transform: `translateX(-${this.value() / this.inputState().max * 100}%)`
    };
  }
  trackFilledStyle() {
    return {
      width: `${this.value() / this.inputState().max * 100}%`
    };
  }
  ngOnDestroy() {
    if (this.#observer) {
      this.#observer.disconnect();
    }
  }
  static {
    this.ɵfac = function SparkleRangeSliderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleRangeSliderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleRangeSliderComponent,
      selectors: [["spk-range-slider"]],
      hostVars: 2,
      hostBindings: function SparkleRangeSliderComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("has-input", ctx.inputField);
        }
      },
      inputs: {
        unit: [1, "unit"],
        value: [1, "value"]
      },
      outputs: {
        value: "valueChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c16,
      decls: 15,
      vars: 12,
      consts: [[1, "label"], [1, "input-wrap"], [1, "min-indicator"], [1, "track-wrap", 3, "click"], [1, "track"], [1, "track-filled"], [1, "thumb-wrap"], [1, "thumb"], [1, "value-indicator"], [1, "max-indicator"]],
      template: function SparkleRangeSliderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c15);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "div", 2);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 3);
          i0.ɵɵlistener("click", function SparkleRangeSliderComponent_Template_div_click_5_listener($event) {
            return ctx.trackEvent($event);
          });
          i0.ɵɵprojection(6, 1);
          i0.ɵɵelementStart(7, "div", 4);
          i0.ɵɵelement(8, "div", 5);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "div", 6)(10, "div", 7)(11, "div", 8);
          i0.ɵɵtext(12);
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(13, "div", 9);
          i0.ɵɵtext(14);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate2("", ctx.inputState().min, "", ctx.unit(), "");
          i0.ɵɵadvance(4);
          i0.ɵɵstyleMap(ctx.trackFilledStyle());
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.thumbWrapStyle());
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.thumbStyle());
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate2("", ctx.value(), "", ctx.unit(), "");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate2("", ctx.inputState().max, "", ctx.unit(), "");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleRangeSliderComponent, [{
    type: Component,
    args: [{
      selector: 'spk-range-slider',
      standalone: true,
      imports: [],
      template: `
    <div class="label">
      <ng-content select="[spkLabel]"></ng-content>
    </div>

    <div class="input-wrap">
      <div class="min-indicator">{{ inputState().min }}{{ unit() }}</div>

      <div class="track-wrap" (click)="trackEvent($event)">
        <ng-content></ng-content>
        <div class="track">
          <div class="track-filled" [style]="trackFilledStyle()"></div>
        </div>
        <div class="thumb-wrap" [style]="thumbWrapStyle()">
          <div class="thumb" [style]="thumbStyle()">
            <div class="value-indicator">{{ value() }}{{ unit() }}</div>
          </div>
        </div>
      </div>

      <div class="max-indicator">{{ inputState().max }}{{ unit() }}</div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    inputField: [{
      type: HostBinding,
      args: ['class.has-input']
    }]
  });
})();
class SparkleOptionComponent {
  constructor() {
    this.value = input(null);
  }
  static {
    this.ɵfac = function SparkleOptionComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleOptionComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleOptionComponent,
      selectors: [["spk-option"]],
      inputs: {
        value: [1, "value"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleOptionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleOptionComponent, [{
    type: Component,
    args: [{
      selector: 'spk-option',
      standalone: true,
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
const COLOR_CLASSES = ['primary', 'accent', 'tertiary', 'warn', 'success'];
class SparkleSelectComponent {
  constructor() {
    this.#BASE_SPACE = 8;
    this.#renderer = inject(Renderer2);
    this.#body = document.getElementsByTagName('body')[0];
    this.#selfRef = inject(ElementRef);
    this.optionsRef = viewChild('optionsRef');
    this.formFieldWrapperRef = viewChild.required('formFieldWrapper');
    this.inputWrapRef = viewChild.required('inputWrap');
    this.inputValue = model('');
    this.selectMultiple = input(false);
    this.hideClearButton = input(false);
    this.displayValue = input('');
    this.displayFn = input(null);
    this.above = input(false);
    this.right = input(false);
    // TODO refactor these signals and their effects to be a computed that returns a signal instead of using effects
    this.#triggerInput = signal(false);
    this.#triggerOption = signal(false);
    this.#inputRef = signal(null);
    this.#previousInputValue = signal('');
    this.#firstOptionIndex = signal(-1);
    this.#hasBeenOpened = signal(false);
    this.#optionInFocus = signal(-1);
    this.isOpen = signal(false);
    this.delayedIsOpen = signal(false);
    this.optionsStyle = signal({
      left: '0px',
      top: '0px'
    });
    this.isSearchInput = computed(() => this.#inputRef()?.type === 'search');
    this.optionsEl = computed(() => this.optionsRef()?.nativeElement);
    this.#options = computed(() => {
      this.#triggerOption();
      const options = this.optionsRef()?.nativeElement.querySelectorAll('option');
      const spkOptions = this.optionsRef()?.nativeElement.querySelectorAll('spk-option');
      if (options?.length) {
        return Array.from(options);
      } else if (spkOptions?.length) {
        return Array.from(spkOptions);
      }
      return [];
    });
    this.chipClass = computed(() => Array.from(this.#selfRef.nativeElement.classList).filter(x => COLOR_CLASSES.includes(x)).join(' '));
    this._displayValue = computed(() => this.displayFn() ? this.displayFn()(this.inputValue()) : this.displayValue() ?? this.inputValue());
    this.selectedOption = computed(() => this.#previousInputValue() ? this.#options().find(x => x.getAttribute('value') === this.#previousInputValue()) : null);
    this.optionsOpenController = null;
    this.inputController = null;
    this.clickController = null;
    this.inputAbortController = null;
    this.#inputRefEffect = effect(() => {
      this.#triggerInput();
      const input = this.#selfRef.nativeElement.querySelector('input');
      if (!input) return;
      this.#createCustomInputEventListener(input);
      input.addEventListener('inputValueChanged', event => {
        this.inputValue.set(event.detail.value);
        if (this.selectMultiple()) {
          this.#updateValueFromInput();
        }
      });
      this.#inputRef.set(input);
      input.autocomplete = 'off';
      if (typeof input.value === 'string') {
        this.inputValue.set(input.value);
      }
    }, {
      allowSignalWrites: true
    });
    this.#onNewInputRef = effect(() => {
      if (this.inputController) {
        this.inputController.abort();
      }
      this.inputController = new AbortController();
      const input = this.#inputRef();
      if (input) {
        input.addEventListener('focus', e => this.open(e), {
          signal: this.inputController?.signal
        });
        input.addEventListener('keydown', e => {
          if (this.selectMultiple() || !this.isSearchInput()) {
            e.preventDefault();
          }
          if (e.key === 'Escape') {
            this.close();
          } else if (e.key === 'ArrowDown') {
            if (this.#optionInFocus() === null || this.#optionInFocus() < 0 || this.#optionInFocus() === this.#options().length - 1) {
              this.#optionInFocus.set(this.#firstOptionIndex());
            } else {
              this.#optionInFocus.set(this.#optionInFocus() + 1);
            }
          } else if (e.key === 'ArrowUp') {
            if (this.#optionInFocus() === null || this.#optionInFocus() < 0 || this.#optionInFocus() === 0) {
              this.#optionInFocus.set(this.#options().length - 1);
            } else {
              this.#optionInFocus.set(this.#optionInFocus() - 1);
            }
          } else if (e.key === 'Enter') {
            if (this.#optionInFocus() > -1) {
              this.selected(this.getOptionElement(this.#optionInFocus()));
            }
          } else if (e.key === 'Tab') {
            this.close(true);
          }
        }, {
          signal: this.inputController?.signal
        });
      }
    });
    this.#optionInFocusEffect = effect(() => {
      if (this.isOpen()) {
        this.#computeFocusedElement();
      }
      setTimeout(() => {
        this.delayedIsOpen.set(this.isOpen());
      }, 0);
    });
    this.#whenInputValueChanged = effect(() => {
      const val = this.inputValue();
      if (this.#inputRef()) {
        this.#inputRef().value = val ?? '';
        this.#inputRef().dispatchEvent(new Event('input'));
      }
    }, {
      allowSignalWrites: true
    });
    this.#inputObserver = new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList' && mutation.target.classList.contains('input')) {
          this.#triggerInput.set(!this.#triggerInput());
        }
        if (mutation.type === 'attributes' && mutation.attributeName === 'value') {}
      }
    });
  }
  #BASE_SPACE;
  #renderer;
  #body;
  #selfRef;
  // TODO refactor these signals and their effects to be a computed that returns a signal instead of using effects
  #triggerInput;
  #triggerOption;
  #inputRef;
  #previousInputValue;
  #firstOptionIndex;
  #hasBeenOpened;
  #optionInFocus;
  #options;
  #inputRefEffect;
  #onNewInputRef;
  #optionInFocusEffect;
  #whenInputValueChanged;
  ngOnInit() {
    this.#setOptionsElement();
    this.#inputObserver.observe(this.inputWrapRef().nativeElement, {
      attributes: true,
      childList: true,
      subtree: true
    });
  }
  getOptionElement(index) {
    return this.#options()[index];
  }
  deselect($event, forceClose = false) {
    $event?.stopPropagation();
    if (this.#inputRef()) {
      this.#inputRef().value = '';
      this.#inputRef().dispatchEvent(new Event('input'));
    }
    this.#optionInFocus.set(this.#firstOptionIndex());
    this.#hasBeenOpened.set(false);
    if (forceClose || !this.selectMultiple()) {
      this.isOpen.set(false);
      this.#inputRef()?.blur();
    }
  }
  selected(el) {
    if (!el) return;
    const newSelectedValue = (el.getAttribute('value') || el.getAttribute('ng-reflect-value')) ?? '';
    const elIndex = this.#options().indexOf(el);
    if (el.hasAttribute('deselect')) {
      return this.deselect(null, true);
    }
    this.#optionInFocus.set(elIndex);
    if (el && !this.selectMultiple()) {
      this.inputValue.set(newSelectedValue);
      for (let i = 0; i < this.#options().length; i++) {
        const option = this.#options()[i];
        if (option.getAttribute('value') === newSelectedValue) {
          this.#renderer.setAttribute(option, 'selected', 'selected');
        } else {
          this.#renderer.removeAttribute(option, 'selected');
        }
      }
    } else if (el && this.selectMultiple()) {
      if (this.inputValue().includes(newSelectedValue)) {
        this.#renderer.removeAttribute(el, 'selected');
        this.toggleOption(newSelectedValue);
      } else {
        this.#renderer.setAttribute(el, 'selected', 'selected');
        this.inputValue.set(this.inputValue().length ? `${this.inputValue()},${newSelectedValue}` : newSelectedValue);
      }
    }
    this.#hasBeenOpened.set(false);
    if (this.selectMultiple()) {
      setTimeout(() => this.#inputRef()?.focus());
    } else {
      this.isOpen.set(false);
      this.#inputRef()?.blur();
    }
  }
  toggleOption(option, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.inputValue.set(this.inputValue().startsWith(option + ',') ? this.inputValue().replace(option + ',', '') : this.inputValue().includes(',' + option) ? this.inputValue().replace(',' + option, '') : this.inputValue().replace(option, ''));
  }
  close(noBlur = false) {
    if (this.isSearchInput() && this.#previousInputValue()) {
      setTimeout(() => this.inputValue.set(this.#previousInputValue()));
    }
    this.#hideOptionsElement();
    this.isOpen.set(false);
    this.#hasBeenOpened.set(false);
    noBlur || this.#inputRef()?.blur();
    this.#killMenuCalculation();
    this.#killClickController();
  }
  open(e) {
    e.preventDefault();
    this.isOpen.set(true);
    this.#setOptionsElement();
    if (this.isSearchInput() && !this.#hasBeenOpened()) {
      this.#previousInputValue.set(this.inputValue());
      this.inputValue.set('');
      this.#inputRef().focus();
      this.#inputRef().value = '';
      this.#inputRef().dispatchEvent(new Event('input'));
      this.#optionInFocus.set(-1);
    } else if (this.isSearchInput() || this.selectMultiple()) {
      this.#inputRef().focus();
    }
    this.#hasBeenOpened.set(true);
    this.#initMenuCalculation();
    this.#listenForClicks();
    this.#triggerOption.set(!this.#triggerOption());
    this.#updateValueFromInput();
  }
  #listenForClicks() {
    if (this.clickController) {
      this.clickController.abort();
    }
    this.clickController = new AbortController();
    window.addEventListener('click', e => {
      if (!e.target) return;
      const closestOption = e.target.closest('option');
      const closestSpkOption = e.target.closest('spk-option');
      const option = closestOption || closestSpkOption;
      const indexOfOption = this.#options().indexOf(option);
      if (indexOfOption > -1) {
        this.selected(option);
      }
    }, {
      signal: this.clickController?.signal
    });
  }
  #killClickController() {
    if (this.clickController) {
      this.clickController.abort();
    }
  }
  #computeFocusedElement() {
    for (let i = 0; i < this.#options().length; i++) {
      const option = this.#options()[i];
      this.#renderer.removeClass(option, 'focused');
      if (this.#optionInFocus() === i) {
        this.#renderer.addClass(option, 'focused');
        option.scrollIntoView({
          block: 'center'
        });
      }
    }
  }
  #updateValueFromInput() {
    const input = this.#inputRef();
    if (!input) return;
    this.inputValue.set(input.value);
    const inputOptions = input.value.split(',');
    setTimeout(() => {
      const options = this.#options();
      for (let index = 0; index < options.length; index++) {
        const val = options[index].getAttribute('ng-reflect-value');
        if (val && inputOptions.includes(val)) {
          this.#renderer.setAttribute(options[index], 'selected', 'selected');
        } else {
          this.#renderer.removeAttribute(options[index], 'selected');
        }
      }
    });
  }
  #setOptionsElement() {
    setTimeout(() => {
      if (this.optionsEl()) {
        this.#renderer.appendChild(this.#body, this.optionsEl());
        this.#firstOptionIndex.set(this.#getIndexOfFirstNonDeselectedOption());
        this.#triggerOption.set(!this.#triggerOption());
      }
    });
  }
  #getIndexOfFirstNonDeselectedOption() {
    const options = this.#options();
    for (let i = 0; i < options.length; i++) {
      if (!options[i].hasAttribute('deselect')) {
        return i;
      }
    }
    return -1;
  }
  #hideOptionsElement() {
    setTimeout(() => {
      if (this.optionsEl()) {
        this.#renderer.removeChild(this.#body, this.optionsEl());
        this.#triggerOption.set(!this.#triggerOption());
      }
    });
  }
  #inputObserver;
  #createCustomInputEventListener(input) {
    Object.defineProperty(input, 'value', {
      configurable: true,
      get() {
        const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
        return descriptor.get.call(this);
      },
      set(newVal) {
        const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
        descriptor.set.call(this, newVal);
        const inputEvent = new CustomEvent('inputValueChanged', {
          bubbles: true,
          cancelable: true,
          detail: {
            value: newVal
          }
        });
        this.dispatchEvent(inputEvent);
        return newVal;
      }
    });
    return input;
  }
  #initMenuCalculation() {
    this.optionsOpenController = new AbortController();
    setTimeout(() => this.#calculateMenuPosition());
    window.addEventListener('resize', () => this.#calculateMenuPosition(), {
      signal: this.optionsOpenController?.signal
    });
    window.addEventListener('scroll', () => this.#calculateMenuPosition(), {
      signal: this.optionsOpenController?.signal
    });
  }
  #killMenuCalculation() {
    if (this.optionsOpenController) {
      this.optionsOpenController.abort();
    }
  }
  #calculateMenuPosition() {
    if (this.optionsEl()) {
      const triggerRect = this.formFieldWrapperRef()?.nativeElement.getBoundingClientRect();
      const menuRect = this.optionsEl().getBoundingClientRect();
      const actionLeftInViewport = triggerRect.left;
      const actionBottomInViewport = triggerRect.bottom;
      let newLeft = actionLeftInViewport;
      let newTop = actionBottomInViewport + this.#BASE_SPACE;
      const outOfBoundsRight = newLeft + menuRect.width > window.innerWidth;
      const outOfBoundsBottom = newTop + menuRect.height > window.innerHeight;
      if (this.above()) {
        const _newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        if (_newTop >= 0) {
          newTop = _newTop;
        }
      } else {
        if (outOfBoundsBottom) {
          newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        }
      }
      if (this.right()) {
        const _newLeft = triggerRect.right - menuRect.width;
        if (_newLeft >= 0) {
          newLeft = _newLeft;
        }
      } else {
        if (outOfBoundsRight) {
          newTop = outOfBoundsBottom ? triggerRect.top + triggerRect.height - menuRect.height : triggerRect.top;
          newLeft = triggerRect.left - menuRect.width - this.#BASE_SPACE;
        }
      }
      this.optionsStyle.set({
        left: newLeft + 'px',
        top: newTop + 'px'
      });
    }
  }
  ngOnDestroy() {
    if (this.clickController) {
      this.clickController.abort();
    }
    if (this.inputController) {
      this.inputController.abort();
    }
    if (this.optionsOpenController) {
      this.optionsOpenController.abort();
    }
    this.#inputObserver.disconnect();
  }
  static {
    this.ɵfac = function SparkleSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSelectComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSelectComponent,
      selectors: [["spk-select"]],
      viewQuery: function SparkleSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.optionsRef, _c17, 5);
          i0.ɵɵviewQuerySignal(ctx.formFieldWrapperRef, _c18, 5);
          i0.ɵɵviewQuerySignal(ctx.inputWrapRef, _c6, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(3);
        }
      },
      inputs: {
        inputValue: [1, "inputValue"],
        selectMultiple: [1, "selectMultiple"],
        hideClearButton: [1, "hideClearButton"],
        displayValue: [1, "displayValue"],
        displayFn: [1, "displayFn"],
        above: [1, "above"],
        right: [1, "right"]
      },
      outputs: {
        inputValue: "inputValueChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c20,
      decls: 13,
      vars: 3,
      consts: [["formFieldWrapper", ""], ["inputWrap", ""], ["optionsRef", ""], [3, "click"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], [1, "display-value"], ["spkSuffix", "", 1, "deselect-indicator"], ["spkSuffix", "", 1, "search-indicator"], ["spkSuffix", "", 1, "select-open-indicator", 3, "open"], [1, "sparkle-popup-menu", 3, "active", "style"], [1, "small", "simple", 3, "ngClass"], [1, "small", "simple", 3, "click", "ngClass"], ["spkSuffix", "", 1, "deselect-indicator", 3, "click"], [1, "default-indicator"], ["spkSuffix", "", 1, "select-open-indicator"], [1, "sparkle-popup-menu"], [1, "sparkle-options-backdrop", 3, "click"], [1, "sparkle-options"], [1, "sparkle-options-placerholder"]],
      template: function SparkleSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c19);
          i0.ɵɵelementStart(0, "div", null, 0)(2, "spk-form-field", 3);
          i0.ɵɵlistener("click", function SparkleSelectComponent_Template_spk_form_field_click_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.open($event));
          });
          i0.ɵɵprojection(3, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵelementStart(4, "div", 4, 1);
          i0.ɵɵtemplate(6, SparkleSelectComponent_Conditional_6_Template, 2, 1, "div", 5)(7, SparkleSelectComponent_Conditional_7_Template, 2, 1, "div", 5);
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(9, SparkleSelectComponent_Conditional_9_Template, 4, 0, "div", 6)(10, SparkleSelectComponent_Conditional_10_Template, 4, 0, "div", 7)(11, SparkleSelectComponent_Conditional_11_Template, 4, 2, "div", 8);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(12, SparkleSelectComponent_Conditional_12_Template, 7, 4, "div", 9);
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵconditional(ctx.selectMultiple() ? 6 : ctx._displayValue() && ctx.isSearchInput() && !ctx.isOpen() || ctx._displayValue() && !ctx.isSearchInput() ? 7 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional((ctx.inputValue() && !ctx.isOpen() && ctx.inputValue().length > 0 || !!ctx.selectedOption()) && !ctx.hideClearButton() ? 9 : ctx.isSearchInput() && !ctx.selectedOption() ? 10 : 11);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.isOpen() ? 12 : -1);
        }
      },
      dependencies: [SparkleFormFieldComponent, SparkleIconComponent, SparkleChipComponent, NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSelectComponent, [{
    type: Component,
    args: [{
      selector: 'spk-select',
      standalone: true,
      imports: [SparkleFormFieldComponent, SparkleIconComponent, SparkleOptionComponent, SparkleChipComponent, NgClass],
      template: `
    <div #formFieldWrapper>
      <spk-form-field (click)="open($event)">
        <ng-content select="label" ngProjectAs="label"></ng-content>

        <div class="input" ngProjectAs="input" #inputWrap>
          @if (selectMultiple()) {
            <div class="display-value">
              @if (inputValue().length > 0) {
                @for (option of inputValue().split(','); track $index) {
                  <spk-chip class="small simple" [ngClass]="chipClass()" (click)="toggleOption(option, $event)">
                    {{ displayFn()?.(option) ?? option }}
                    <spk-icon>x-circle</spk-icon>
                  </spk-chip>
                }
              }
            </div>
          } @else if ((_displayValue() && isSearchInput() && !isOpen()) || (_displayValue() && !isSearchInput())) {
            <div class="display-value">{{ _displayValue() }}</div>
          }
          <ng-content select="input"></ng-content>
        </div>

        @if (((inputValue() && !isOpen() && inputValue()!.length > 0) || !!selectedOption()) && !hideClearButton()) {
          <div class="deselect-indicator" (click)="deselect($event)" spkSuffix>
            <ng-content select="[deselect-indicator]"></ng-content>
            <spk-icon class="default-indicator">x-circle</spk-icon>
          </div>
        } @else if (isSearchInput() && !selectedOption()) {
          <div class="search-indicator" spkSuffix>
            <ng-content select="[search-indicator]"></ng-content>
            <spk-icon class="default-indicator">magnifying-glass</spk-icon>
          </div>
        } @else {
          <div class="select-open-indicator" [class.open]="isOpen()" spkSuffix>
            <ng-content select="[open-indicator]"></ng-content>
            <spk-icon class="default-indicator">caret-down</spk-icon>
          </div>
        }
      </spk-form-field>
    </div>

    @if (isOpen()) {
      <div class="sparkle-popup-menu" [class.active]="delayedIsOpen()" #optionsRef [style]="optionsStyle()">
        <div class="sparkle-options-backdrop" (click)="close()"></div>
        <div class="sparkle-options">
          <div class="sparkle-options-placerholder"><ng-content select="[placeholder-text]" /></div>
          <ng-content select="[options]"></ng-content>
        </div>
      </div>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleSidenavComponent {
  constructor() {
    this.openWidth = 280;
    this.openWidthTreshold = this.openWidth * 0.9;
    this.disableDrag = input(false);
    this.isOpen = model(false);
    this.type = input('');
    this.dragImageElement = viewChild.required('dragImageElement');
    this.dragIsEnding = signal(false);
    this.dragIsOnScreen = signal(true);
    this.isDragging = signal(false);
    this.dragPositionX = signal(0);
    this.dragActualPositionX = computed(() => {
      const dragPosition = this.dragPositionX();
      const overdragFactor = 0.32;
      if (dragPosition > this.openWidthTreshold) {
        return this.openWidthTreshold + (dragPosition - this.openWidthTreshold) * overdragFactor;
      }
      return dragPosition;
    });
    this.draggingStyle = computed(() => {
      if (this.isDragging()) {
        return `translateX(${this.dragActualPositionX()}px)`;
      }
      return this.isOpen() && this.type() === 'overlay' ? `translateX(${this.openWidth}px)` : `translateX(0px)`;
    });
    this.draggingEffect = effect(() => {
      // Disable scrolling when dragging
      if (this.isDragging()) {
        document.body.classList.add('dragging');
      }
    });
  }
  drop(e) {
    e.stopPropagation();
    this.#drop(e.clientX);
  }
  #drop(clientX) {
    this.isDragging.set(false);
    if (clientX <= 0) {
      this.isOpen.set(false);
    } else if (clientX > this.openWidthTreshold) {
      this.isOpen.set(true);
    } else if (clientX < this.openWidthTreshold) {
      if (!this.isOpen() && clientX < this.openWidthTreshold * 0.6) {
        this.isOpen.set(true);
      } else {
        this.isOpen.set(false);
      }
    }
  }
  dragEnd(e) {
    if (e.clientX < 0 || !this.dragIsOnScreen()) {
      this.#drop(0);
    }
  }
  dragEnter() {
    this.dragIsOnScreen.set(true);
  }
  dragLeave() {
    this.dragIsOnScreen.set(false);
  }
  dragOver(e) {
    e.preventDefault();
    this.#setDragImage(e);
  }
  dragStart(e) {
    e.stopPropagation();
    this.isDragging.set(true);
    this.#setDragImage(e);
  }
  #setDragImage(e) {
    e.dataTransfer?.setDragImage(this.dragImageElement().nativeElement, 0, 0);
  }
  drag(e) {
    e.stopPropagation();
    setTimeout(() => {
      this.isDragging() && this.dragIsOnScreen() && this.dragPositionX.set(e.clientX);
    });
    this.#setDragImage(e);
  }
  touchStart(e) {
    e.stopPropagation();
    this.isDragging.set(true);
  }
  touchMove(e) {
    e.stopPropagation();
    setTimeout(() => {
      this.isDragging() && this.dragIsOnScreen() && this.dragPositionX.set(e.touches[0].clientX);
    });
  }
  touchEnd(e) {
    e.stopPropagation();
    this.#drop(e.changedTouches[0].clientX);
  }
  touchCancel(e) {
    e.stopPropagation();
    this.isDragging.set(false);
  }
  static {
    this.ɵfac = function SparkleSidenavComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSidenavComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSidenavComponent,
      selectors: [["spk-sidenav"]],
      viewQuery: function SparkleSidenavComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.dragImageElement, _c21, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 10,
      hostBindings: function SparkleSidenavComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("open", ctx.isOpen())("closed", !ctx.isOpen())("overlay", ctx.type() === "overlay")("simple", ctx.type() === "simple")("is-dragging", ctx.isDragging());
        }
      },
      inputs: {
        disableDrag: [1, "disableDrag"],
        isOpen: [1, "isOpen"],
        type: [1, "type"]
      },
      outputs: {
        isOpen: "isOpenChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c23,
      decls: 11,
      vars: 4,
      consts: [["dragImageElement", ""], [1, "drag-image"], [1, "dropping-surface"], [1, "sidenav"], [1, "main-wrap"], ["draggable", "true", 1, "dragable"], [1, "closed-topbar"], [1, "dropping-surface", 3, "drop", "dragover", "dragenter", "dragleave"], ["draggable", "true", 1, "dragable", 3, "dragstart", "dragend", "drag", "touchstart", "touchmove", "touchend", "touchcancel"]],
      template: function SparkleSidenavComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c22);
          i0.ɵɵelement(0, "div", 1, 0);
          i0.ɵɵtemplate(2, SparkleSidenavComponent_Conditional_2_Template, 1, 0, "div", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 4);
          i0.ɵɵtemplate(6, SparkleSidenavComponent_Conditional_6_Template, 1, 0, "div", 5);
          i0.ɵɵelementStart(7, "div", 6);
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "main");
          i0.ɵɵprojection(10, 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.isDragging() ? 2 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵstyleProp("transform", ctx.draggingStyle());
          i0.ɵɵadvance();
          i0.ɵɵconditional(!ctx.disableDrag() ? 6 : -1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSidenavComponent, [{
    type: Component,
    args: [{
      selector: 'spk-sidenav',
      standalone: true,
      imports: [SparkleButtonComponent],
      template: `
    <div #dragImageElement class="drag-image"></div>

    @if (isDragging()) {
      <div
        class="dropping-surface"
        (drop)="drop($event)"
        (dragover)="dragOver($event)"
        (dragenter)="dragEnter()"
        (dragleave)="dragLeave()"></div>
    }

    <div class="sidenav">
      <ng-content select="[sidenav]"></ng-content>
    </div>

    <div class="main-wrap" [style.transform]="draggingStyle()">
      @if (!disableDrag()) {
        <div
          class="dragable"
          draggable="true"
          (dragstart)="dragStart($event)"
          (dragend)="dragEnd($event)"
          (drag)="drag($event)"
          (touchstart)="touchStart($event)"
          (touchmove)="touchMove($event)"
          (touchend)="touchEnd($event)"
          (touchcancel)="touchCancel($event)"></div>
      }

      <div class="closed-topbar">
        <ng-content select="[sidenav-closed-topbar]"></ng-content>
      </div>

      <main>
        <ng-content></ng-content>
      </main>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.open]': 'isOpen()',
        '[class.closed]': '!isOpen()',
        '[class.overlay]': 'type() === "overlay"',
        '[class.simple]': 'type() === "simple"',
        '[class.is-dragging]': 'isDragging()'
      }
    }]
  }], null, null);
})();
class GridSortableDirective {
  #selfRef = inject(ElementRef);
  #renderer = inject(Renderer2);
  #draggedItem = null;
  onDragStart(event) {
    this.#draggedItem = event.target;
    this.updateOrder(); // Initialize order for all children
  }
  onDragOver(event) {
    event.preventDefault();
  }
  onDragEnter(event) {
    const target = event.target;
    if (this.#draggedItem && target !== this.#draggedItem && target.parentNode === this.#draggedItem.parentNode) {
      const parent = this.#draggedItem.parentNode;
      const draggedIndex = Array.from(parent.children).indexOf(this.#draggedItem);
      const targetIndex = Array.from(parent.children).indexOf(target);
      // Update only the flipped indexes
      this.#renderer.setStyle(this.#draggedItem, 'order', targetIndex);
      this.#renderer.setStyle(target, 'order', draggedIndex);
    }
  }
  updateOrder(excludedItem = null) {
    const items = this.#selfRef.nativeElement.children;
    Array.from(items).forEach((item, index) => {
      if (item !== excludedItem) {
        this.#renderer.setStyle(item, 'order', index);
      }
    });
  }
  static {
    this.ɵfac = function GridSortableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GridSortableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GridSortableDirective,
      selectors: [["", "gridSortable", ""]],
      hostBindings: function GridSortableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragstart", function GridSortableDirective_dragstart_HostBindingHandler($event) {
            return ctx.onDragStart($event);
          })("dragover", function GridSortableDirective_dragover_HostBindingHandler($event) {
            return ctx.onDragOver($event);
          })("dragenter", function GridSortableDirective_dragenter_HostBindingHandler($event) {
            return ctx.onDragEnter($event);
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridSortableDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[gridSortable]'
    }]
  }], null, {
    onDragStart: [{
      type: HostListener,
      args: ['dragstart', ['$event']]
    }],
    onDragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragEnter: [{
      type: HostListener,
      args: ['dragenter', ['$event']]
    }]
  });
})();
class SparkleSortableComponent {
  constructor() {
    this.templateOne = contentChild.required('templateOne');
    this.templateTwo = contentChild.required('templateTwo');
    this.placeholderRef = viewChild.required('placeholderRef');
    this.items = model([]);
  }
  static {
    this.ɵfac = function SparkleSortableComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSortableComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSortableComponent,
      selectors: [["spk-sortable"]],
      contentQueries: function SparkleSortableComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.templateOne, _c24, 5);
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.templateTwo, _c25, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      viewQuery: function SparkleSortableComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.placeholderRef, _c26, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        items: [1, "items"]
      },
      outputs: {
        items: "itemsChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [["placeholderRef", ""], [1, "placeholder"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function SparkleSortableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
          i0.ɵɵrepeaterCreate(2, SparkleSortableComponent_For_3_Template, 2, 1, null, null, i0.ɵɵrepeaterTrackByIndex);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.items());
        }
      },
      dependencies: [NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSortableComponent, [{
    type: Component,
    args: [{
      selector: 'spk-sortable',
      standalone: true,
      imports: [NgTemplateOutlet],
      template: `
    <div class="placeholder" #placeholderRef></div>

    @for (item of items(); track $index) {
      @if ($index === 0) {
        <ng-container *ngTemplateOutlet="templateOne(); context: { $implicit: item }"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="templateTwo(); context: { $implicit: item }"></ng-container>
      }
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleSortableDirective {
  constructor() {
    this.#selfEl = inject(ElementRef);
    this.#renderer = inject(Renderer2);
    this.#placeholderEl = signal(null);
    this.#parentGap = signal(0);
    this.dragStartIndex = signal(-1);
    this.dragToIndex = signal(-1);
    this.dragables = signal([]);
    this.afterDrop = output();
    this.abortController = null;
    this.draggingEffect = effect(() => {
      const currentDragPosIndex = this.dragToIndex();
      const startIndex = this.dragStartIndex();
      if (currentDragPosIndex > -1 && startIndex > -1) {
        const dragables = this.dragables();
        const placeholderEl = this.#placeholderEl();
        const gapValue = this.#parentGap();
        const draggedElement = dragables[startIndex];
        const totalShift = draggedElement.offsetHeight + gapValue;
        let placeholderElShift = 0;
        if (currentDragPosIndex > startIndex) {
          for (let i = startIndex + 1; i <= currentDragPosIndex; i++) {
            placeholderElShift += dragables[i].offsetHeight + gapValue;
          }
        } else if (currentDragPosIndex < startIndex) {
          for (let i = startIndex - 1; i >= currentDragPosIndex; i--) {
            placeholderElShift -= dragables[i].offsetHeight + gapValue;
          }
        }
        if (placeholderEl) {
          const newTransform = `translateY(${placeholderElShift}px)`;
          if (placeholderEl.style.transform !== newTransform) {
            this.#renderer.setStyle(placeholderEl, 'transform', newTransform);
          }
        }
        for (let i = 0; i < dragables.length; i++) {
          if (i === startIndex || i === dragables.length - 1) continue;
          let newTransform = 'translateY(0)';
          if (currentDragPosIndex > startIndex && currentDragPosIndex >= i && startIndex < i) {
            newTransform = `translateY(${-totalShift}px)`;
          } else if (currentDragPosIndex < startIndex && currentDragPosIndex <= i && startIndex > i) {
            newTransform = `translateY(${totalShift}px)`;
          }
          if (dragables[i].style.transform !== newTransform) {
            this.#renderer.setStyle(dragables[i], 'transform', newTransform);
          }
        }
      }
    });
    this.draggablesEffect = effect(() => {
      const els = this.dragables();
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      for (let index = 0; index < els.length; index++) {
        els[index].addEventListener('dragstart', e => this.dragStart(e), {
          signal: this.abortController.signal
        });
        els[index].addEventListener('dragenter', e => this.dragEnter(e), {
          signal: this.abortController.signal,
          capture: true
        });
      }
    });
    this.#dragableObserver = new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList') {
          this.dragables.set(Array.from(this.#selfEl.nativeElement.querySelectorAll('[draggable]')));
        }
      }
    });
  }
  #selfEl;
  #renderer;
  #placeholderEl;
  #parentGap;
  getIndexOfElement(element) {
    return this.dragables().findIndex(el => el == element);
  }
  dragEnter(e) {
    const draggableAncestor = e.target.closest('[draggable]');
    if (draggableAncestor && !draggableAncestor.classList.contains('spk-placeholder')) {
      this.dragToIndex.set(this.getIndexOfElement(draggableAncestor));
    }
  }
  dragStart(e) {
    if (e.target) {
      const targetElement = e.target;
      const currentTarget = document.elementFromPoint(e.clientX, e.clientY);
      const isSortingHandle = currentTarget?.hasAttribute('sort-handle') || currentTarget?.closest('[sort-handle]') !== null;
      let draggedElement;
      if (isSortingHandle) {
        draggedElement = targetElement.closest('[draggable]');
      } else {
        draggedElement = targetElement;
        if (draggedElement.parentElement?.querySelector('[sort-handle]') !== null || !draggedElement.draggable) {
          e.preventDefault();
          return;
        }
      }
      const parentStyle = window.getComputedStyle(draggedElement.parentElement);
      this.#parentGap.set(parseFloat(parentStyle.gap) || 0);
      const draggedElementIndex = this.getIndexOfElement(draggedElement);
      this.dragStartIndex.set(draggedElementIndex);
      this.#renderer.setStyle(this.dragables()[draggedElementIndex], 'opacity', '0');
      this.#renderer.setStyle(this.dragables()[draggedElementIndex], 'zIndex', '2');
      this.#renderer.addClass(this.#selfEl.nativeElement, 'dragging');
      setTimeout(() => {
        const placeholderElement = draggedElement.cloneNode(true);
        this.#renderer.addClass(placeholderElement, 'spk-placeholder');
        this.#renderer.setStyle(placeholderElement, 'left', `${draggedElement.offsetLeft}px`);
        this.#renderer.setStyle(placeholderElement, 'width', `${draggedElement.offsetWidth}px`);
        this.#renderer.setStyle(placeholderElement, 'top', `${draggedElement.offsetTop}px`);
        this.#renderer.setStyle(placeholderElement, 'zIndex', '1');
        this.#renderer.setStyle(placeholderElement, 'opacity', '.4');
        this.#placeholderEl.set(placeholderElement);
        this.#selfEl.nativeElement.appendChild(placeholderElement);
      });
    }
  }
  ngOnInit() {
    this.#dragableObserver.observe(this.#selfEl.nativeElement, {
      childList: true,
      subtree: false
    });
  }
  dragOver(e) {
    e.preventDefault();
  }
  drop() {
    this.#resetStyles();
    this.afterDrop.emit({
      fromIndex: this.dragStartIndex(),
      toIndex: this.dragToIndex()
    });
    this.dragStartIndex.set(-1);
    this.dragToIndex.set(-1);
    this.#selfEl.nativeElement.classList.remove('dragging');
    if (this.#placeholderEl()) {
      this.#selfEl.nativeElement.removeChild(this.#placeholderEl());
    }
  }
  #resetStyles() {
    const dragables = this.dragables();
    for (let i = 0; i < dragables.length; i++) {
      const el = dragables[i];
      this.#renderer.setStyle(el, 'transform', '');
      this.#renderer.setStyle(el, 'opacity', '1');
      this.#renderer.setStyle(el, 'zIndex', '1');
    }
  }
  #dragableObserver;
  ngOnDestroy() {
    this.#dragableObserver.disconnect();
    if (this.abortController) {
      this.abortController.abort();
    }
  }
  static {
    this.ɵfac = function SparkleSortableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSortableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleSortableDirective,
      selectors: [["", "spkSortable", ""]],
      hostBindings: function SparkleSortableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragover", function SparkleSortableDirective_dragover_HostBindingHandler($event) {
            return ctx.dragOver($event);
          })("drop", function SparkleSortableDirective_drop_HostBindingHandler() {
            return ctx.drop();
          });
        }
      },
      outputs: {
        afterDrop: "afterDrop"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSortableDirective, [{
    type: Directive,
    args: [{
      selector: '[spkSortable]',
      standalone: true
    }]
  }], null, {
    dragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    drop: [{
      type: HostListener,
      args: ['drop']
    }]
  });
})();
function moveIndex(array, event) {
  const {
    fromIndex,
    toIndex
  } = event;
  if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
    return array;
  }
  const newArray = [...array];
  const [removedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, removedItem);
  return newArray;
}
class SparkleStepperComponent {
  static {
    this.ɵfac = function SparkleStepperComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleStepperComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleStepperComponent,
      selectors: [["spk-stepper"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleStepperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleStepperComponent, [{
    type: Component,
    args: [{
      selector: 'spk-stepper',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleRowComponent {
  static {
    this.ɵfac = function SparkleRowComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleRowComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleRowComponent,
      selectors: [["spk-row"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleRowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleRowComponent, [{
    type: Component,
    args: [{
      selector: 'spk-row',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleColumnComponent {
  static {
    this.ɵfac = function SparkleColumnComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleColumnComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleColumnComponent,
      selectors: [["spk-column"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleColumnComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleColumnComponent, [{
    type: Component,
    args: [{
      selector: 'spk-column',
      standalone: true,
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleTableComponent {
  constructor() {
    this.tableColumns = input([]);
    this.loading = input(false);
    this.columnSizes = computed(() => {
      return this.tableColumns().reduce((acc, col, index) => {
        const last = index === this.tableColumns().length - 1;
        if (last) {
          return `${acc} max-content`;
        }
        return `${acc} 1fr`;
      }, '');
    });
    this.data = input([]);
    this.dataChange = output();
    this.#initialData = null;
    this.#initialDataSet = signal(false);
    this.sortByColumn = model(null);
    this.e = effect(() => {
      const sortByColumn = this.sortByColumn();
      if (sortByColumn === null) {
        if (!this.#initialDataSet()) {
          this.#initialData = this.data();
          this.#initialDataSet.set(true);
        }
        return this.dataChange.emit(JSON.parse(JSON.stringify(this.#initialData)));
      }
      const column = sortByColumn.startsWith('-') ? sortByColumn.slice(1) : sortByColumn;
      const isDescending = sortByColumn.startsWith('-');
      const sortedData = this.data().sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];
        let comparison = 0;
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          comparison = valueA - valueB;
        }
        if (valueA instanceof Date && valueB instanceof Date) {
          comparison = valueA.getTime() - valueB.getTime();
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          comparison = valueA.localeCompare(valueB, undefined, {
            sensitivity: 'base'
          });
        }
        return isDescending ? -comparison : comparison;
      });
      this.dataChange.emit(sortedData);
    }, {
      allowSignalWrites: true
    });
  }
  #initialData;
  #initialDataSet;
  toggleSort(column) {
    const currentSort = this.sortByColumn();
    const sortDir = currentSort === column ? `-${column}` : currentSort === `-${column}` ? null : column;
    this.sortByColumn.set(sortDir);
  }
  static {
    this.ɵfac = function SparkleTableComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleTableComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleTableComponent,
      selectors: [["spk-table"]],
      hostVars: 2,
      hostBindings: function SparkleTableComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("grid-template-columns", ctx.columnSizes());
        }
      },
      inputs: {
        tableColumns: [1, "tableColumns"],
        loading: [1, "loading"],
        data: [1, "data"],
        sortByColumn: [1, "sortByColumn"]
      },
      outputs: {
        dataChange: "dataChange",
        sortByColumn: "sortByColumnChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c29,
      decls: 6,
      vars: 2,
      consts: [[1, "indeterminate"], [1, "no-rows"]],
      template: function SparkleTableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c28);
          i0.ɵɵelementStart(0, "thead");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, SparkleTableComponent_Conditional_2_Template, 1, 0, "spk-progress-bar", 0);
          i0.ɵɵelementStart(3, "tbody");
          i0.ɵɵprojection(4, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, SparkleTableComponent_Conditional_5_Template, 2, 0, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.loading() ? 2 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ctx.loading() ? 5 : -1);
        }
      },
      dependencies: [SparkleProgressBarComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleTableComponent, [{
    type: Component,
    args: [{
      selector: 'spk-table',
      standalone: true,
      imports: [SparkleProgressBarComponent],
      template: `
    <thead>
      <ng-content select="[table-header]" />
    </thead>

    @if (loading()) {
      <spk-progress-bar class="indeterminate" />
    }

    <tbody>
      <ng-content />
    </tbody>

    @if (!loading()) {
      <div class="no-rows">
        <ng-content select="[table-no-rows]" />
      </div>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[style.grid-template-columns]': 'columnSizes()'
      }
    }]
  }], null, null);
})();
class SparkleToggleCardComponent {
  constructor() {
    this.isActive = model(false);
    this.disallowToggle = input(false);
  }
  ngOnInit() {
    if (this.disallowToggle()) {
      this.isActive.set(true);
    }
  }
  toggle() {
    this.isActive.set(!this.isActive());
  }
  static {
    this.ɵfac = function SparkleToggleCardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleToggleCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleToggleCardComponent,
      selectors: [["spk-toggle-card"]],
      hostVars: 2,
      hostBindings: function SparkleToggleCardComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("active", ctx.isActive());
        }
      },
      inputs: {
        isActive: [1, "isActive"],
        disallowToggle: [1, "disallowToggle"]
      },
      outputs: {
        isActive: "isActiveChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c31,
      decls: 6,
      vars: 1,
      consts: [[3, "click"], [1, "content"]],
      template: function SparkleToggleCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c30);
          i0.ɵɵelementStart(0, "h3", 0);
          i0.ɵɵlistener("click", function SparkleToggleCardComponent_Template_h3_click_0_listener() {
            return ctx.disallowToggle() || ctx.toggle();
          });
          i0.ɵɵprojection(1, 0, null, SparkleToggleCardComponent_ProjectionFallback_1_Template, 1, 0);
          i0.ɵɵtemplate(3, SparkleToggleCardComponent_Conditional_3_Template, 2, 0, "spk-icon");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 1);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ctx.disallowToggle() ? 3 : -1);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleToggleCardComponent, [{
    type: Component,
    args: [{
      selector: 'spk-toggle-card',
      standalone: true,
      imports: [SparkleIconComponent],
      template: `
    <h3 (click)="disallowToggle() || toggle()">
      <ng-content select="[title]">Title</ng-content>

      @if (!disallowToggle()) {
        <spk-icon>caret-down</spk-icon>
      }
    </h3>

    <div class="content">
      <ng-content></ng-content>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.active]': 'isActive()'
      }
    }]
  }], null, null);
})();
class SparkleToggleComponent {
  static {
    this.ɵfac = function SparkleToggleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleToggleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleToggleComponent,
      selectors: [["spk-toggle"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 0,
      consts: [[1, "box"], [1, "knob"]],
      template: function SparkleToggleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(2);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleToggleComponent, [{
    type: Component,
    args: [{
      selector: 'spk-toggle',
      standalone: true,
      imports: [],
      template: `
    <div class="box">
      <div class="knob"></div>
    </div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleFileDragDropDirective {
  constructor() {
    this.fileOver = signal(false);
    this.fileDropped = output();
  }
  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver.set(true);
  }
  onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver.set(false);
  }
  ondrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver.set(false);
    const files = e.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
  static {
    this.ɵfac = function SparkleFileDragDropDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFileDragDropDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleFileDragDropDirective,
      selectors: [["", "spkFileDragDrop", ""]],
      hostVars: 2,
      hostBindings: function SparkleFileDragDropDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragover", function SparkleFileDragDropDirective_dragover_HostBindingHandler($event) {
            return ctx.onDragOver($event);
          })("dragleave", function SparkleFileDragDropDirective_dragleave_HostBindingHandler($event) {
            return ctx.onDragLeave($event);
          })("drop", function SparkleFileDragDropDirective_drop_HostBindingHandler($event) {
            return ctx.ondrop($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassProp("fileover", ctx.fileOver());
        }
      },
      outputs: {
        fileDropped: "fileDropped"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFileDragDropDirective, [{
    type: Directive,
    args: [{
      selector: '[spkFileDragDrop]',
      standalone: true,
      host: {
        '[class.fileover]': 'fileOver()'
      }
    }]
  }], null, {
    onDragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragLeave: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }],
    ondrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();
class SparklePreventWheelDirective {
  wheel(event) {
    event.preventDefault();
  }
  static {
    this.ɵfac = function SparklePreventWheelDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparklePreventWheelDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparklePreventWheelDirective,
      selectors: [["", "spkPreventWheel", ""]],
      hostBindings: function SparklePreventWheelDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("wheel", function SparklePreventWheelDirective_wheel_HostBindingHandler($event) {
            return ctx.wheel($event);
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparklePreventWheelDirective, [{
    type: Directive,
    args: [{
      selector: '[spkPreventWheel]',
      standalone: true
    }]
  }], null, {
    wheel: [{
      type: HostListener,
      args: ['wheel', ['$event']]
    }]
  });
})();

/*
 * Public API Surface of sparkle-ui
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GridSortableDirective, SparkleAlertComponent, SparkleAlertContainerComponent, SparkleAlertModule, SparkleAlertService, SparkleButtonComponent, SparkleButtonGroupComponent, SparkleCardComponent, SparkleCheckboxComponent, SparkleChipComponent, SparkleColumnComponent, SparkleDividerComponent, SparkleFileDragDropDirective, SparkleFileUploadComponent, SparkleFormFieldComponent, SparkleIconComponent, SparkleListComponent, SparkleMenuComponent, SparkleOptionComponent, SparklePreventWheelDirective, SparkleProgressBarComponent, SparkleRadioComponent, SparkleRangeSliderComponent, SparkleRowComponent, SparkleSelectComponent, SparkleSidenavComponent, SparkleSortableComponent, SparkleSortableDirective, SparkleStepperComponent, SparkleTableComponent, SparkleToggleComponent, moveIndex };
